<script>
import DefaultMixin from '../mixin'
import ConsoleDashColaboradores from "components/console/components/suporte/dashboard/Colaboradores.vue"
import ConsoleTasksQueue from "components/console/components/suporte/dashboard/FilaTarefas.vue"
import ConsoleChamadosAbertos from "components/console/components/suporte/dashboard/ChamadosAbertos.vue"
import ConsoleUserTasksDashboard from "components/console/components/suporte/dashboard/UserTasks.vue"
import ConsoleTaskCount from "components/console/components/suporte/dashboard/EstatisticasTarefas.vue"
import ConsoleTaskClientCount from "components/console/components/suporte/dashboard/EstatisticasTarefasClientes.vue"

export default {
  name: 'ConsoleDashboard',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    ConsoleTaskCount,
    ConsoleTaskClientCount,
    ConsoleUserTasksDashboard,
    ConsoleChamadosAbertos,
    ConsoleTasksQueue,
    ConsoleDashColaboradores
  },
  data() {
    return {
      menuActive: 'suporte'
    }
  },
  computed: {
  },
  beforeMount () {
  },
  watch: {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
  },
  meta: {
    title: 'Console',
    name: 'Console'
  }
}
</script>

<template>
    <div class="console-dashboard">
      <div class="ev2-body">
        <div class="content-limit">
          <div class="content flex">
            <console-user-tasks-dashboard />
          </div>
          <div class="content flex">
            <console-dash-colaboradores />
            <console-task-count />
            <console-task-client-count />
          </div>
          <div class="content flex">
            <console-chamados-abertos />
            <console-tasks-queue />
          </div>
          <div class="content flex">
          </div>
        </div>
      </div>
    </div>
</template>
