<template>
  <div class="ev2-box">
    <div class="header">
      <div class="hcol1">
        <div class="title">Estatísticas de Tarefas</div>
        <div class="subtitle">Resumo sobre as tarefas ativas</div>
      </div>
      <div class="hcol2">
        <i class="fal fa-refresh cursor-pointer" @click="load" v-if="!loading" />
        <sl-loading v-else class="size-18" content="" />
      </div>
    </div>
    <div class="body m-t">
      <div class="task-count">
        <div class="count">
          <div class="lbl">Para análise:</div>
          <div class="val-container">
            <div class="value actives">{{ stats.ativas }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Pendentes:</div>
          <div class="val-container">
            <div class="value pending">{{ stats.pendentes }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Em andamento:</div>
          <div class="val-container">
            <div class="value started">{{ stats.emAndamento }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Para homologar:</div>
          <div class="val-container">
            <div class="value homolog">{{ stats.homologar }}</div>
          </div>
        </div>
        <div class="hr" />
        <div class="count m-t-lg">
          <div class="lbl">Abertas hoje:</div>
          <div class="val-container">
            <div class="value abertas">{{ stats.abertasHoje }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Fechadas hoje:</div>
          <div class="val-container">
            <div class="value fechadas">{{ stats.fechadasHoje }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {taskStats} from "@/domain/gerencia/stats/services";
import SlLoading from "components/layout/components/Loading.vue";

export default {
  name: "ConsoleTaskCount",
  components: {SlLoading},
  data () {
    return {
      loading: true,
      stats: {
        ativas: 0,
        pendentes: 0,
        emAndamento: 0,
        homologar: 0,
        abertasHoje: 0,
        fechadasHoje: 0
      }
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      taskStats()
          .then(response => {
            this.stats = Object.assign(this.stats, response.data)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    }
  }
}
</script>
