<template>
  <div class="ev2-box console-tasks-queue hscroll">
    <div class="header">
      <div class="hcol1">
        <div class="title">Chamados para Análise</div>
        <div class="subtitle">Tarefas em aberto aguardando análise</div>
      </div>
      <div class="hcol2">
        <i class="fal fa-refresh cursor-pointer" @click="load" />
        <erp-select v-model="buscaTipo" :options="buscaTipoOpts" class="sl-input input-sm m-l-sm" style="display: none" />
      </div>
    </div>
    <div class="body m-t">
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="ev2-dash-table with-bottom size-sm time-lista"
          :rows-per-page-options="[20, 50, 100]"
      >
        <u-tr slot="header" slot-scope="props">
          <u-th v-for="col in props.cols" :key="col.name" :style="parseStyle(col)" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-center">
            </div>
          </u-th>
        </u-tr>

        <u-tr class="colaborador" slot="body" slot-scope="props" :props="props" :class="bindTrClass(props.row)">
          <u-td style="width: 40px !important; max-width: 40px" key="id" :props="props">
            {{props.row.id}}
          </u-td>
          <u-td class="td-limit l-130" key="cliente" :props="props">
            <div v-if="props.row.cliente">
              {{props.row.cliente.name}}
            </div>
            <span v-else></span>
          </u-td>
          <u-td style="width: 40px !important; max-width: 40px" class="" key="queue" :props="props">
            {{props.row.queuePosition}}
          </u-td>
          <u-td class="td-limit l-200" key="description" :props="props">
            <div class="cursor-pointer" @click="$router.push({name: 'console.tarefa', params: {id: props.row.id}})">{{props.row.description}}</div>
          </u-td>
          <u-td class="text-right" style="text-align: right!important;" key="createdAt" :props="props">
            {{props.row.createdAt|formatDate}}
          </u-td>
        </u-tr>
      </u-table>
    </div>
<!--    <div class="footer">
      <div class="text-center">
        <u-btn class="f-btn" no-caps label="Novo Registro" />
      </div>
    </div>-->
  </div>
</template>

<script>
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import ErpSelect from "@/plugins/uloc-erp/components/form/select"
import tableColumnsCache from "@/utils/tableColumnsCache"
import {UTable, UTr, UTh, UTd, date, UPopover} from "uloc-vue"
import {datePtToEn} from "@/utils/date"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item.vue"
import taskListWindow from '@/components/console/components/suporte/dashboard/window/taskListWindow'
import {list} from "@/domain/tarefas/services"
import {differenceInHours, parseISO} from "date-fns"

let filters = {
  id: null,
}

const listName = 'table.console.dash.taskOpened'
const listStorage = tableColumnsCache(listName, [
  {label: 'ID', name: 'id', active: true, sortable: true, ordering: 1},
  {label: 'Cliente', name: 'cliente', active: true, sortable: true, ordering: 2},
  {label: 'Fila', name: 'queue', active: false, sortable: true, ordering: 3},
  {label: 'Tarefa', name: 'description', active: true, sortable: true, ordering: 4},
  {label: 'Abertura', name: 'createdAt', active: true, sortable: true, ordering: 5},
], 0)
export default {
  name: "ConsoleChamadosAbertos",
  components: {
    ErpSelect,
    UTable, UTr, UTh, UTd
  },
  data () {
    const filtros = JSON.parse(JSON.stringify(filters))
    filtros.internalStatus = ['opened']
    filtros.status = ['open']
    filtros.withoutQueue = 1
    return {
      busca: null,
      buscaTipo: 'ativos',
      buscaTipoOpts: [
        {label: 'Ativos', value: 'ativos'},
        {label: 'Com sobrecarga', value: 'sobrecarga'},
        {label: 'Sem atribuição', value: 'semTarefa'},
      ],
      listStorage: listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 100
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        })/*.concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ])*/,
        filter: '',
        filters: filtros,
        selected: [],
        loading: false
      }
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  methods: {
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || (!this.table.filters.data2 || this.table.filters.data2.length < 10)) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.busca && extraFilters.push(`&search=${String(this.table.filters.busca).replace('#', '_hash-')}`)

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      !this.fila && this.table.filters.internalStatus && extraFilters.push(`&internalStatus=${this.table.filters.internalStatus.join(',')}`)
      this.table.filters.status && extraFilters.push(`&status=${this.table.filters.status.join(',')}`)
      this.table.filters.tipo && extraFilters.push(`&type=${this.table.filters.tipo}`)
      this.table.filters.active && extraFilters.push(`&active=${this.table.filters.active}`)
      this.table.filters.cliente && extraFilters.push(`&client=${this.table.filters.cliente.id}`)
      this.table.filters.assigned && extraFilters.push(`&assigned=${this.table.filters.assigned.id}`)
      this.table.filters.lider && extraFilters.push(`&owner=${this.table.filters.lider.id}`)
      this.table.filters.permission && extraFilters.push(`&permission=${this.table.filters.permission}`)
      this.table.filters.public && extraFilters.push(`&public=${this.table.filters.public}`)
      this.table.filters.project && extraFilters.push(`&project=${this.table.filters.project}`)
      this.table.filters.priority && extraFilters.push(`&priority=${this.table.filters.priority.id}`)
      this.table.filters.type && extraFilters.push(`&type=${this.table.filters.type.id}`)
      this.table.filters.fila && extraFilters.push(`&queue=${this.table.filters.fila.replace('id:', '')}`)
      // Filtra somente tarefas vencidas
      this.table.filters.dueFilter && extraFilters.push(`&dueFilter=${this.table.filters.dueFilter}`)
      this.table.filters.withoutQueue && extraFilters.push(`&withoutQueue=${this.table.filters.withoutQueue}`)

      /*if (Array.isArray(this.tags) && this.tags.length > 0) {
        extraFilters.push(`&tags=${this.tags.join(',')}`)
      }*/

      if (this.extraFilters && Array.isArray(this.extraFilters)) {
        this.extraFilters.map(ef => {
          extraFilters.push('&' + ef)
        })
      }

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'id'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : false}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, this.isSuporte, this.isGerencia)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    showTasklist (list) {
      this.taskListWindow(list)
    },
    taskListWindow,
    tratarHorasTrabalhadas (time) {
      let parts = time.split(':')
      return parts[0] + ':' + parts[1]
    },
    bindTrClass (task) {
      const css = []
      task.deadline && this.isOpen(task) && this.atraso(task.deadline) > 0 && css.push('is-atrasado')
      return css
    },
    isOpen (task) {
      return task.status.type === 'opened'
    },
    atraso (d) {
      return Math.ceil(differenceInHours(parseISO(d.date || d), new Date()) / 24) * -1
    },
    parseStyle (col) {
      let css = ''
      if (['id', 'queue'].includes(col.name)) {
        css = css + 'width: 40px; max-width: 40px;'
      }
      if (['createdAt'].includes(col.name)) {
        css = css + 'text-align: right !important; display: flex; flex-direction: row-reverse'
      }
      return css
    }
  }
}
</script>
