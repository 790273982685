import http from '../../../../utils/services/http'
export const teamStats = (filtros) => {
  let url = `/api/gerencia/teamStats?${filtros}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const taskStats = () => {
  let url = `/api/gerencia/taskStats`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const taskClientStats = () => {
  let url = `/api/gerencia/taskClientStats`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const financeiroStats = () => {
  let url = `/api/gerencia/financeiroStats`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const clienteAlertStats = () => {
  let url = `/api/gerencia/clienteAtencaoStats`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
