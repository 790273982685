<template>
  <div class="ev2-box">
    <div class="header">
      <div class="hcol1">
        <div class="title">Estatísticas de Clientes</div>
        <div class="subtitle">Clientes com mais demandas ativas</div>
      </div>
      <div class="hcol2">
        <i class="fal fa-refresh cursor-pointer" @click="load" v-if="!loading" />
        <sl-loading v-else class="size-18" content="" />
      </div>
    </div>
    <div class="body m-t-lg">
      <div class="task-client-stats">
        <div v-for="s in stats" :key="s.clienteId" class="client">
          <div class="lbl" style="cursor: default">
            {{ firstLastname(s.clienteNome) }}
            <u-tooltip :offset="[12,12]" anchor="bottom left" self="top left">
              {{s.clienteNome}}
            </u-tooltip>
          </div>
          <div class="graph">
            <span class="graph-val" :style="`width: ${s.porcentagem2}%`"></span>
            <span class="count">{{ s.tarefas }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {taskClientStats} from "@/domain/gerencia/stats/services"
import SlLoading from "components/layout/components/Loading.vue";

export default {
  name: "ConsoleTaskClientCount",
  components: {SlLoading},
  data () {
    return {
      loading: true,
      stats: []
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      taskClientStats()
          .then(response => {
            this.stats = response.data
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    }
  }
}
</script>
