export default function (tasks) {
    let window = 'task.list'
    !this.$uloc.window.get(window) && this.$uloc.window.new({
        wid: window,
        title: 'Lista de Tarefas',
        move: true,
        backdrop: false,
        clickOutside: false,
        closeOnEsc: true,
        windowClass: 'erp-window-modern v2',
        width: '800',
        height: '520',
        props: {
            tasks: tasks,
            $router: this.$router,
            router: this.$router
        }
    }, () => import('./TaskList.vue'))
        .then((wid) => {
            this.$uloc.window.listen(wid, {
                updated: (wid, val) => {
                    console.log('Update payment event')
                },
                created: (wid, val) => {
                    console.log('Create payment event')
                }
            })
        }) // return wid
}
